<template>
  <div>
    <router-link :to="{ name: 'CRM' }">Zurück zum CRM</router-link>
    <v-row>
      <v-col cols="11" xl="4">
        <v-text-field
          v-model="searchStr"
          outlined
          label="Nach E-Mail-Adresse suchen"
          append-icon="mdi-magnify"
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>
      </v-col>
      <v-col cols="1"></v-col>

      <!-- Stat Buttons -->
      <v-col cols="12" xl="7">
        <v-row>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.totalUserCount }}
                </div>
                <div class="caption">
                  Gesamt
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.freeUserCount }}
                </div>
                <div class="caption">
                  Testmonat
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.freeUserCount }}
                </div>
                <div class="caption">
                  Kostenlos
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.payingUsers }}
                </div>
                <div class="caption">
                  Bezahlt
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.inactiveUserCount }}
                </div>
                <div class="caption">
                  Inaktiv
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.canceledUserCount }}
                </div>
                <div class="caption">
                  Gekündigt
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.monthlyIncome.toFixed(2) }}€
                </div>
                <div class="caption">
                  p. M.
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="2" md="1">
            <v-card class="unvergessen-shadow card">
              <v-responsive :aspect-ratio="1/1">
                <div class="title">
                  {{ stats.totalIncome.toFixed(2) }}€
                </div>
                <div class="caption">
                  insg.
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="4" md="2">
            <v-card class="unvergessen-shadow card" @click="$router.push({ name: 'PageStatistics' })">
              <v-responsive>
                <div style="padding: 14px" class="caption">
                  Alle Statistiken<br>anzeigen
                </div>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-alert type="error" v-model="userNotFound">
      Nutzer konnte nicht gefunden werden
    </v-alert>
    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="users"
      class="unvergessen-shadow"
      :items-per-page="-1"
    >
      <template v-slot:item.ct="{ item }">
        <div style="width: 100%; height: 100%; cursor: pointer; padding-top: 15px" @click="handleClick(item)">
          <span>{{ new Date(item.ct).toLocaleDateString() }}</span>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <span>{{ item.status === 'active' ? 'Aktiv' : item.status === 'canceled' ? 'Gekündigt' : 'Ausgelaufen' }}</span>
      </template>
      <template v-slot:item.variant="{ item }">
        <span>
          {{ item.variant === 'paypal' || item.variant === 'sepa' || item.variant === 'invoice' || item.variant === 'card' ? 'Bezahlt' : item.variant === 'manualOverwrite' ? 'Phils Extrawurst' : item.variant === 'recommendation' ? 'Empfehlungsprogramm' : 'Testmonat' }}
        </span>
      </template>
      <template v-slot:item.actions="{ item}">
        <v-icon @click.stop="handleNotes(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <div v-if="index > 0 && !hideInfinite" v-intersect="loadMore"></div>

    <!-- Dialogs -->
    <v-dialog max-width="600px" v-model="noteDialog">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorNotes">
          Etwas ist schief gelaufen. Bitte versuche es erneut.
        </v-alert>
        <v-textarea outlined v-model="currentNote"></v-textarea>
        <div style="padding: 20px; text-align: center">
          <v-btn class="success" @click="editNote">
            Speichern
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      index: 0,
      hideInfinite: false,
      noteDialog: false,
      errorNotes: false,
      currentNote: '',
      currentItem: {},
      currentIndex: -1,
      userNotFound: false,
      searchStr: '',
      stats: {
        freeUserCount: 0,
        monthlyIncome: 0,
        payingUsers: 0,
        totalIncome: 0,
        totalUserCount: 0,
        inactiveUserCount: 0,
        canceledUserCount: 0
      },
      users: [],
      headers: [
        {
          text: 'Erstellt',
          align: 'start',
          value: 'ct'
        },
        { text: 'Email', value: 'mail' },
        { text: 'Status', value: 'status' },
        { text: 'Variante', value: 'variant' },
        { text: 'Ort', value: 'location' },
        { text: 'ID', value: 'ids' },
        { text: 'Häufigkeit', value: 'cycle' },
        { text: 'OB', value: 'onboarding' },
        { text: 'Core', value: 'core' },
        { text: 'Com', value: 'com' },
        { text: 'Sur', value: 'sur' },
        { text: 'Umsatz', value: 'revenue' },
        { text: 'Kommentar', value: 'notes' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },
  methods: {
    handleClick (value) {
      this.$router.push({ name: 'CRMMailSingleUser', params: { uid: value._id } })
    },
    handleNotes (item) {
      this.currentNote = item.notes
      this.currentItem = item
      this.currentIndex = this.users.indexOf(item)
      this.noteDialog = true
    },
    editNote () {
      this.currentItem.notes = this.currentNote
      if (this.currentIndex > -1) {
        axios.patch('/crm/memorialMail/user/notes', { uid: this.currentItem._id, notes: this.currentNote })
          .then(() => {
            Object.assign(this.users[this.currentIndex], this.currentItem)
            this.currentIndex = -1
            this.currentItem = {}
            this.currentNote = ''
            this.noteDialog = false
            this.errorNotes = false
          })
          .catch(() => {
            this.errorNotes = true
          })
      }
    },
    search () {
      axios.post('/crm/memorialMail/user/search', { mail: this.searchStr })
        .then(res => {
          this.userNotFound = false
          this.hideInfinite = true
          var arr = []
          this.users = []
          arr.push(res.data)
          this.users = arr
        })
        .catch(() => {
          this.users = []
          this.userNotFound = true
        })
    },
    loadMore () {
      axios.get('/crm/memorialMail', { params: { set: this.index } })
        .then(res => {
          if (res.data.users.length === 0) {
            this.hideInfinite = true
          } else {
            this.users = this.users.concat(res.data.users)
            this.index++
          }
        })
    }
  },
  created () {
    axios.get('/crm/memorialMail', { params: { set: 0 } })
      .then(res => {
        // Fill stats
        this.stats.freeUserCount = res.data.freeUserCount
        this.stats.monthlyIncome = res.data.monthlyIncome
        this.stats.payingUsers = res.data.payingUsers
        this.stats.totalIncome = res.data.totalIncome
        this.stats.totalUserCount = res.data.totalUserCount
        this.stats.inactiveUserCount = res.data.inactiveUserCount
        this.stats.canceledUserCount = res.data.canceledUserCount

        this.users = res.data.users
        this.index = 1
      })
  }
}
</script>

<style scoped>
.card {
  text-align: center;
  min-width: 70px;
  border-radius: 10px !important;
  margin-right: 30px
}

* >>> .v-responsive__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center
}

* >>> td {
  font-size: 12px !important;
}
</style>
